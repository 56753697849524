import { Component, OnInit } from '@angular/core';
import { LoggingService } from './logging.service'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'MWweb';
  constructor(private loggingService: LoggingService){
    loggingService.logPageView('App Start');
  }
}
