<br /><br />

<div class="mw-main-section mw-main-lightgrey">
    <br /><br />
    <div class="row">
        <div class="col" style="text-align:center">
            <h1 class="mw-header">Public Sector Services</h1> <br /><br />
            <h3>MacLeod Watts has provided services and certified<br />valuations for over 300 public sector clients. Our<br />services include:</h3>
        </div>
    </div>
    <br /><br />
    <div class="row">


        <div class="col" style="text-align:center">
            <table style="margin: 0 auto">

                <!--------------------------------------------------------------------------------------------------------------------------->
                <tr>
                    <td>
                        <img src="../../assets/icon_medical.png" width="100" /><br /><br />

                    </td>
                    <td><img src="../../assets/1px.png" width="100" /></td>
                    <td>
                        <img src="../../assets/icon_roll.png" width="100" /><br /><br />

                    </td>
                </tr>
                <tr>
                    <td style="vertical-align:top">
                        <p class="mw-blue-text">Medical plan and other post-employment<br />benefit actuarial valuations</p>
                        <p class="mw-service-sm-text"><span class="glyphicon glyphicon-ok" aria-hidden="true"></span> Fulfilling requirements of GASB 75</p>
                        <p class="mw-service-sm-text"><span class="glyphicon glyphicon-ok" aria-hidden="true"></span> Determination of contribution levels according to your policy</p>
                        <p class="mw-service-sm-text"><span class="glyphicon glyphicon-ok" aria-hidden="true"></span> Exploring costs associated with plan changes</p>
                        <p class="mw-service-sm-text"><span class="glyphicon glyphicon-ok" aria-hidden="true"></span> Projection of future expected benefit payments, trust sufficiency</p>
                        <p class="mw-service-sm-text"><span class="glyphicon glyphicon-ok" aria-hidden="true"></span> Risk analysis associated with various possible future scenarios</p>
                    </td>
                    <td></td>
                    <td style="vertical-align:top">
                        <p class="mw-blue-text">Roll-forward valuations for GASB 75<br />reporting in interim years</p>
                        <p class="mw-service-sm-text"><span class="glyphicon glyphicon-ok" aria-hidden="true"></span> Preparation of GASB 75 results based on prior valuations</p>
                    </td>
                </tr>

                <!--------------------------------------------------------------------------------------------------------------------------->
                <tr>
                    <td colspan="2"><img src="../../assets/1px.png" height="80"></td>
                </tr>
                <!--------------------------------------------------------------------------------------------------------------------------->

                <tr>
                    <td>
                        <img src="../../assets/icon_plans.png" width="100" /><br /><br />

                    </td>
                    <td><img src="../../assets/1px.png" width="100" /></td>
                    <td>
                        <img src="../../assets/icon_funding.png" width="100" /><br /><br />

                    </td>
                </tr>
                <tr>
                    <td style="vertical-align:top">
                        <p class="mw-blue-text">Plan Design Consulting</p>
                        <p class="mw-service-sm-text"><span class="glyphicon glyphicon-ok" aria-hidden="true"></span> Explore alternative plan designs and their potential cost impact</p>
                    </td>
                    <td></td>
                    <td style="vertical-align:top">
                        <p class="mw-blue-text">Funding Policy Development</p>
                        <p class="mw-service-sm-text">
                            <span class="glyphicon glyphicon-ok" aria-hidden="true"></span>
                            Explore the alternative costs of various funding policies and<br />their impact on your financial statements
                        </p>
                    </td>
                </tr>

                <!--------------------------------------------------------------------------------------------------------------------------->
                <tr>
                    <td colspan="2"><img src="../../assets/1px.png" height="80"></td>
                </tr>
                <!--------------------------------------------------------------------------------------------------------------------------->

                <tr>
                    <td>
                        <img src="../../assets/icon_present.png" width="100" /><br /><br />

                    </td>
                    <td>
                    <td></td>
                </tr>
                <tr>
                    <td>
                        <p class="mw-blue-text">Executive Non-qualified Pension plan<br />Valuations and Design</p>
                        <p class="mw-service-sm-text">
                            <span class="glyphicon glyphicon-ok" aria-hidden="true"></span> We can take you from first design to last benefit calculation for<br />
                            your top executives and explore various informal funding options<br />for these plans
                        </p>
                    </td>
                    <td></td>
                    <td></td>
                </tr>

            </table>

        </div>

    </div>
    <br /><br />
</div>

<br /><br /><br /><br />