

<!-- Top Nav -->
<div class="row mw-nav-background" style="background-image:url(../assets/mw_nav_background.png); background-size:cover">

  <div class="col" style="text-align:center"><br /><a [routerLink]="['']"><img src="../assets/mw_logo.png" width="50" style="vertical-align:middle" /></a><br /><br /></div>

  <div class="col" style="text-align:center"><br /><br /> <a [routerLink]="['']"><img src="../assets/mw_banner.png" width="250" /></a></div>

  <div class="col" style="vertical-align:text-bottom">
      <br /><br />
      <a class="mw-nav-link" [routerLink]="['/about']">about us</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <a class="mw-nav-link" [routerLink]="['/services']">services</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <a class="mw-nav-link" [routerLink]="['/staff']">our staff</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <a class="mw-nav-link" [routerLink]="['/contact']">contact us</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <img src="../assets/icon_main_vol.png" width="20" data-toggle="tooltip" data-placement="left" title="m&#477;-KLOWD Wots" />
  </div>
</div>

<!-- Content -->
<div class="content" role="main">

  <router-outlet></router-outlet>
</div>


<!-- Footer -->
<div class="mw-footer">
  <br />
  <div class="row">
      <div class="col" style="text-align:center">
          <div class="container" style="text-align:center">


              <div class="container">
                  <table align="center" border="0">
                      <tr style="height:45px">
                          <td style="text-align:left; text-decoration:underline">Navigation&nbsp;&nbsp;&nbsp;&nbsp;</td>
                          <td style="width:80px"></td>
                          <td style="text-align:left; text-decoration:underline">Services&nbsp;&nbsp;&nbsp;&nbsp;</td>
                          <td style="width:80px"></td>
                          <td style="text-align:left; text-decoration:underline">Contact Us&nbsp;&nbsp;&nbsp;&nbsp;</td>
                      </tr>
                      <tr>
                          <td style="text-align:left">
                             <a class="mw-nav-footer-link" [routerLink]="['']">home</a><Br>
                             <a class="mw-nav-footer-link" [routerLink]="['/about']">about us</a><br />
                             <a class="mw-nav-footer-link" [routerLink]="['/services']">services</a><br />
                             <a class="mw-nav-footer-link" [routerLink]="['/staff']">our staff</a><br />
                             <a class="mw-nav-footer-link" [routerLink]="['/contact']">contact us</a><br />
                          </td>
                          <td style="width:80px"></td>
                          <td style="text-align:left; vertical-align:top;">
                             <a class="mw-nav-footer-link" [routerLink]="['/services/public']">public sector services</a><Br>
                             <a class="mw-nav-footer-link" [routerLink]="['/services/private']">private sector services</a><Br>
                          </td>
                             <td style="width:80px"></td>
                              <td style="text-align:left; vertical-align:top; font-size:12px">
                              (503) 419-0466
                          </td>
                      </tr>
                  </table>
              </div>
              <div class="container" style="font-size:12px; text-align:center">
                  <br />
                  <p style="text-align:center"> &copy; 2020 MacLeod Watts Inc. </p>
              </div>
          </div>
      </div>
  </div>
</div>

