<figure class=" full-width">
    <img src="../../assets/mw_main_hero.png" style="max-width:100%" class="mw-hero" />
</figure>


<br /><br />


<div class="mw-main-section mw-main-lightgrey">
    <br /><br />
    <div class="row justify-content-md-center">

        <div class="col">

        </div>
        <div class="col-md-auto">
            <h1 class="mw-header">Our Company</h1><br /><br />
            We provide public sector actuarial services to states, counties, cities, <br /> and other public agencies.<br /><br />

            The actuaries at MacLeod Watts have provided consulting services to over<br />
            300 public sector clients from special district agencies to state governments.
            <br /><br />
            We have developed a reputation of understandable reports delivered on time<br /> 
            and on budget. We focus on personal attention to our clients to help clear the<br />
            fog of the complex reporting and funding requirements for
            public sector post-<br />
            employment benefits.
            <br /><br />
 
        </div>
        <div class="col">
            <img src="../../assets/mw_main_charts.png" width="200" />
        </div>
        <div class="col">

        </div>
    </div>
    <br /><br />
</div>

<div class="mw-main-section">
    <br /><br />
    <div class="row justify-content-md-center">
        <div class="col-md-auto">
            <h1 class="mw-header">Services</h1><br />
        </div>
    </div>

    <div class="row justify-content-md-center">
        <div class="col-md-auto" style="text-align:center">
            <a [routerLink]="['/services/public']"><img src="../../assets/mw_main_group_icon.png" width="65" /></a><br /><br />
            <h3 class="mw-header">Public Sector<br />Services</h3> <br />
            We have provided services<br />
            and certified valuations for over 300<br />
            public sector clients
        </div>
        <div class="col-md-auto">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
        <div class="col-md-auto" style="text-align:center">
            <a [routerLink]="['/services/private']">
                <img src="../../assets/mw_main_manager_icon.png" width="55" /></a><br /><br />
                <h3 class="mw-header">Private Sector<br />Services</h3><br />
                We also provide services for private<br />
                sector companies that<br />
                sponsor post-employment<br />
                healthcare benefits

</div>
    </div>

    <br /><br />
</div>

<div class=" mw-main-dark-grey">
    <br /><br />
    <div class="row justify-content-md-center">
        <div class="col-md-auto">
            <h1 class="mw-header" style="color:white">Our Actuaries</h1><br />
        </div>
    </div>

    <div class="row justify-content-md-center">
        <div class="col-md-auto" style="text-align:center">
            <img src="../../assets/mw_main_cm_portrait.png" width="125" /><br /><br />

            <p style="font-size:14px">Catherine MacLeod</p>
            Consultant<br />
            FSA, FCA, EA, MAAAA
            <br /><br />
            <a [routerLink]="['/staff']" class="mw-bio-link-text">CLICK FOR BIO</a>
               
        </div>
        <div class="col-md-auto"></div>
        <div class="col-md-auto" style="text-align:center">
            <img src="../../assets/mw_main_kw_portrait.png" width="125" /><br /><br />
            <p style="font-size:14px">Kevin Watts</p>
            Consultant<br />
            FSA, FCA, MAAAA
            <br /><br />
            <a [routerLink]="['/staff']" class="mw-bio-link-text">CLICK FOR BIO</a>
           <br />
        </div>
    </div>

    <br /><br /><br />
</div>

<br /><br /><br />