import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-servicesprivate',
  templateUrl: './servicesprivate.component.html',
  styleUrls: ['./servicesprivate.component.css']
})
export class ServicesprivateComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
