<br /><br />

<div class="mw-main-section mw-main-lightgrey">
    <br /><br />
    <div class="row">
        <div class="col" style="text-align:center">
            <h1 class="mw-header">Private Sector Services</h1> <br /><br />
            <h3>For those private sector companies that sponsor post-<br />employment healthcare benefits, our services include:</h3>
        </div>
    </div>
    <br /><br />
    <div class="row">


        <div class="col" style="text-align:center">
            <table style="margin: 0 auto">

                <!--------------------------------------------------------------------------------------------------------------------------->
                <tr>
                    <td>
                        <img src="../../assets/icon_analytics.png" width="100" /><br /><br />

                    </td>
                    <td><img src="../../assets/1px.png" width="100" /></td>
                    <td>
                        <img src="../../assets/icon_profits.png" width="100" /><br /><br />

                    </td>
                </tr>
                <tr>
                    <td style="vertical-align:top">
                        <p class="mw-blue-text">Actuarial Valuations for financial<br />reporting under standards promulgated<br />by FASB</p>
                        
                    </td>
                    <td></td>
                    <td style="vertical-align:top">
                        <p class="mw-blue-text">Determination of contribution levels<br />according to your funding policy</p>
                    </td>
                </tr>

                <!--------------------------------------------------------------------------------------------------------------------------->
                <tr>
                    <td colspan="2"><img src="../../assets/1px.png" height="80">
                </td>
                <!--------------------------------------------------------------------------------------------------------------------------->

                <tr>
                    <td>
                        <img src="../../assets/icon_plans.png" width="100" /><br /><br />
                    </td>
                    <td><img src="../../assets/1px.png" width="100" /></td>
                    <td>
                        <img src="../../assets/icon_funding.png" width="100" /><br /><br />

                    </td>
                </tr>
                <tr>
                    <td style="vertical-align:top">
                        <p class="mw-blue-text">Plan Design Consulting</p>
                        <p class="mw-service-sm-text"><span class="glyphicon glyphicon-ok" aria-hidden="true"></span> Explore alternative plan designs and their potential cost inpact</p>
                    </td>
                    <td></td>
                    <td style="vertical-align:top">
                        <p class="mw-blue-text">Funding Policy Development</p>
                        <p class="mw-service-sm-text">
                            <span class="glyphicon glyphicon-ok" aria-hidden="true"></span>
                        Explore the alternative costs of various funding policies and<br />their impact on your financial statements</p>
                    </td>
                </tr>

                <!--------------------------------------------------------------------------------------------------------------------------->
                <tr>
                    <td colspan="2"><img src="../../assets/1px.png" height="80"></td>
                </tr>
                <!--------------------------------------------------------------------------------------------------------------------------->

                <tr>
                    <td>
                        <img src="../../assets/icon_management.png" width="100" /><br /><br />

                    </td>
                    <td>
                    <td>
                        

                    </td>
                </tr>
                <tr>
                    <td>
                        <p class="mw-blue-text">Executive Non-qualified Pension plan<br />Valuations and Design</p>
                        <p class="mw-service-sm-text"><span class="glyphicon glyphicon-ok" aria-hidden="true"></span> We can take you from first design to last benefit calculation for<br />
                        your top executives and explore various informal funding options<br />for these plans</p>
                    </td>
                    <td></td>
                    <td>
                        
                    </td>
                </tr>

            </table>

        </div>

    </div>
    <br /><br />
</div>

<br /><br /><br /><br />