<br /><br />

<div class="mw-main-section mw-main-lightgrey">
    <br /><br />
    <div class="row justify-content-md-center">

        <div class="col">

        </div>
        <div class="col-md-auto">
            <h1 class="mw-header">Our Company</h1><br /><br />
            We provide public sector actuarial services to states,counties, cities, <br /> and other public agencies<br />

            The actuaries at MacLeod Watts have provided consulting services to over 300 public<br />
            sector clients from special district agencies to state governments.
            <br />
            We have developed a reputation for understandable reports delivered on time<br /> and on budget.

            We focus on personal attention to our clients to <br />help clear the fog of the complex reporting<br /> and funding requirements for
            public sector post-employment benefits.
            <br />
        </div>
        <div class="col">
            <img src="../../assets/mw_main_charts.png" width="200" />
        </div>
        <div class="col">

        </div>
    </div>
    <br /><br />
</div>

<br /><br /><br /><br />