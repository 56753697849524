<br /><br />

      <div class="mw-main-section mw-main-lightgrey">
          <br /><br />
          <div class="row">

              <div class="col">

              </div>

              <div class="col">
                  <p class="mw-green-header-text">Catherine MacLeod, Consultant, FSA, FCA, EA, MAAAA</p>
                  <hr style="width:850px; border-top: 1px solid #000000;" />
                  <br />
                  <table>
                      <tr>
                          <td>
                              <img src="../../assets/mw_main_cm_portrait.png" width="150" />
                          </td>
                          <td><img src="../../assets/1px.png" width="20" /></td>
                          <td>
                              <p>
                                  Catherine has been providing actuarial services to both public and private sector clients for over 30 years. Since<br />
                                  2008 she's focused on post-employment benefits for public sector clients. Her clients rely on her<br />
                                  accessibility and focus on delivering understandable reports and presentations.<br />
                                  Catherine is a Fellow of the Society of Actuaries. She speaks at various conferences and serves on the board of<br />
                                  local charities. Prior to founding MacLeod Watts, Catherine was Director of Post-employment Benefits at Bickmore.
                                  <br /><br /><br /><br /><br /><br />
                                  <span class="mw-green-header-text-sm">Contact Catherine</span> <span style="font-weight:bold; font-size:16px">|</span> Phone: (503) 419-0462
                              </p>
                              
                          </td>
                      </tr>
                  </table>
                  <br />
              </div>

              <div class="col">

              </div>
          </div>
          <br /><br />

          <div class="row">

              <div class="col">

              </div>

              <div class="col">
                  <p class="mw-green-header-text">Kevin Watts, Consultant, FSA, FCA, MAAAA</p>
                  <hr style="width:850px; border-top: 1px solid #000000;" />
                  <br />
                  <table>
                      <tr>
                          <td>
                              <img src="../../assets/mw_main_kw_portrait.png" width="150" />
                          </td>
                          <td><img src="../../assets/1px.png" width="20" /></td>
                          <td>
                              <p>
                                  For over 30 years Kevin has worked with both small and large public and private sector clients on the most<br />
                                  complex and challenging assignments. Kevin's clients rely on his ability to distill their complex situations<br />
                                  into understandable solutions. Since 2014 Kevin's focus as been on public sector post-employment benefits.<br />
                                  Kevin is a Fellow of the Socienty of Actuaries. He's presented at various industry and professional conferences.<br />
                                  Kevin has served on the Committee on Professional Responsibility for the American Academy of Actuaries and<br />
                                  has served on the board of various local charities. Prior to founding MacLeod Watts, Kevin was Manager of<br />
                                  Post-employment Benefits at Bickmore.
                                  <br /><br /><br /><br />
                                  <span class="mw-green-header-text-sm">Contact Kevin</span> <span style="font-weight:bold; font-size:16px">|</span> Phone: (503) 419-0465
                              </p>
                              
                          </td>
                      </tr>
                  </table>
              </div>

              <div class="col">

              </div>
          </div>
          <br /><br /><br />
      </div>

<br /><br /><br /><br /><br />
