import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TmplAstTemplate } from '@angular/compiler';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  title: string  = "Home Page";
  constructor() { }

  ngOnInit(): void {
    
  }

}
