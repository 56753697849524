<br /><br />

<div class="mw-main-section mw-main-lightgrey">
    <br /><br />
          <div class="row">

              <div class="col">

              </div>
              <div class="col" style="text-align:center">
                  <img src="../../assets/mw_main_group_icon.png" /><br /><br />
                  <button type="button" class="btn btn-outline-secondary btn-lg" [routerLink]="['/services/public']">Public Sector</button>
              </div>

              <div class="col" style="text-align:center">
                  <img src="../../assets/mw_main_manager_icon.png" /><br /><br />
                  <button type="button" class="btn btn-outline-secondary btn-lg" [routerLink]="['/services/private']">Private Sector</button>


              </div>

              <div class="col">

              </div>
          </div>
    <br /><br />
</div>

<br /><br /><br /><br />
